<template>
  <pr-popup
    v-model="loginVisible"
    class="login-modal card-3"
    :fullscreen-on-mobile="true">
    <template #header>
      {{ $t('loginToPR') }}
    </template>

    <div class="form">
      <div class="phone-filed">
        <intl-tel-input
          v-model="phone"
          :country-code="countryCode ? countryCode.toLowerCase() : 'us'"
          :to-front="regionCodes"></intl-tel-input>
        <span
          :class="{ touchable: time === 60, hoverable: time === 60 }"
          @click="send">{{ buttonTitle }}</span>
      </div>

      <mt-field
        v-model="captcha"
        :placeholder="$t('enterCaptcha')"
        type="text"></mt-field>

      <p>{{ $t('loginAutoCreate') }}</p>

      <pr-button
        type="primary"
        size="large"
        :loading="logining"
        @click.native="login">
        {{ $t('login') }}
      </pr-button>
    </div>
  </pr-popup>
</template>


<script>
import PrButton from '@/components/PRButton.vue'
import IntlTelInput from '@/components/IntlTelInput.vue'
import { regionCodes } from '@/config'
import { useResponsive } from '@/composables/responsive'
import { useApplication } from '@/pinia/application'
import { computed, ref } from 'vue'
import { useAuthenticator } from '@/pinia/authenticator'
//@ts-ignore
import { Indicator } from 'mint-ui'
import { useI18n } from 'vue-i18n-composable'
import { sendSMS } from '@/api/system'
import { useCurrentCountry } from '@/composables/country'
import { useToast } from '@/composables/toast'
import PrPopup from '@/components/PRPopup.vue'


export default {
  setup () {
    const application = useApplication()
    const authenticator = useAuthenticator()
    const phone = ref({
      phoneNumber: '',
      rawPhoneNumber: '',
    })
    const captcha = ref('')
    const enabled = ref(true)
    const { t } = useI18n()
    const time = ref(60)
    const logining = ref(false)
    const { Toast } = useToast()

    const loginVisible = computed({
      get: () => application.loginVisible,
      set: (value) => {
        application.updateLoginVisible(value)
      }
    })

    const login = () => {

      const credentials = {
        phoneNumber: `+${phone.value.phoneNumber}`,
        captcha: captcha.value,
        rawPhoneNumber: phone.value.rawPhoneNumber,
      }
      logining.value = true
      authenticator.login(credentials)
      logining.value = false
    }

    const startCount = () => {
      const intervalId = setInterval(() => {
        time.value -= 1

        if (time.value === 0) {
          enabled.value = true
          time.value = 60
          clearInterval(intervalId)
        }
      }, 1000)
    }

    const send = async () => {
      try {
        if (!enabled.value) {
          return
        }

        enabled.value = false

        Indicator.open()

        await sendSMS(`+${phone.value.phoneNumber}`, phone.value.rawPhoneNumber)
        startCount()
        Toast({
          message: t('sendOK'),
        }, 'ok')
      } catch (err) {
        Toast({
          message: err.message,
        })
        enabled.value = true
      } finally {
        Indicator.close()
      }
    }

    return {
      regionCodes,
      ...useResponsive(),
      loginVisible,
      login,
      phone,
      captcha,
      enabled,
      send,
      time,
      logining,
      ...useCurrentCountry(),
    }
  },
  computed: {
    buttonTitle () {
      if (this.time === 60) {
        return this.$t('getCaptcha')
      } else {
        return this.$t('timeleft', { time: this.time })
      }
    }
  },
  components: {
    PrButton,
    IntlTelInput,
    PrPopup,
  }
}
</script>

<style>
.login-modal .p-dialog {
  width: 22rem;
}
</style>

<style scoped lang="less">
@import '@/assets/base.less';

.form {
  margin: 54px auto;
  width: 88%;
  max-width: 420px;

  button {
    height: 43px;
    border-radius: 4px;
  }

  > p {
    font-size: 0.8rem;
    color: @minor;
  }
}

.mint-cell::after {
  content: none;
}

button {
  margin: 40px auto;
}

.phone-filed {
  position: relative;

  span {
    color: @blue;
    display: block;
    position: absolute;
    right: 0px;
    top: 0px;

    &:not(.touchable) {
      color: @light-grey;
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

.phone-filed .mint-field {
  max-width: 70%;
}
</style>
