//@ts-check
import { computed, ref, watch } from "vue"
import { useRoute } from "vue-router/composables"
import { useToast } from "./toast"
import { useUserInfo } from "./user_info"
import { useApplication } from "@/pinia/application"


export const useIssueCreate = function (assigneeRef = null) {
  const assigneeId = useRoute().params.userId
  const currency = ref('USD')
  const price = ref(0)
  const content = ref('')
  const publicIssue = ref(true)
  const { showToast } = useToast()
  if (!assigneeRef) {
    const composition = useUserInfo(assigneeId)
    assigneeRef = composition.user
  }
  const application = useApplication()

  const priceCent = computed(() => {
    if (currency.value === 'JPY') {
      return Math.round(price.value)
    }

    return Math.round(price.value * 100)
  })

  const minPrice = computed(() => {
    if (!assigneeRef.value) {
      return 0
    }

    if (currency.value === 'JPY') {
      return assigneeRef.value.issue.minPrices[currency.value]
    }

    return Number((assigneeRef.value.issue.minPrices[currency.value] / 100).toFixed(2))
  })

  const bargainPriceText = computed(() => {
    if (typeof price.value !== 'number') {
      return null
    }
    if (!assigneeRef.value) {
      return null
    }
    if (currency.value === 'JPY') {
      return `${assigneeRef.value.issue.minPrices[currency.value]} ${currency.value}`
    }
    return `${(assigneeRef.value.issue.minPrices[currency.value] / 100).toFixed(2)} ${currency.value}`
  })

  const resolvePriceText = computed(() => {
    if (typeof price.value !== 'number') {
      return null
    }
    if (!assigneeRef.value) {
      return null
    }
    if (currency.value === 'JPY') {
      return `${price.value - assigneeRef.value.issue.minPrices[currency.value]} ${currency.value}`
    }
    return `${((Math.round(price.value * 100) - assigneeRef.value.issue.minPrices[currency.value]) / 100).toFixed(2)} ${currency.value}`
  })

  watch(assigneeRef, () => {
    if (assigneeRef.value && assigneeRef.value.issue) {
      content.value = assigneeRef.value.issue.template
    }
  })

  const currencies = [{
    label: 'USD',
    value: 'USD',
  }, {
    label: 'CNY',
    value: 'CNY',
  }, {
    label: 'JPY',
    value: 'JPY',
  }, {
    label: 'EUR',
    value: 'EUR',
  }]

  const assign = async function () {
    try {
      if (price.value <= 0) {
        throw new Error('Price must be greater than 0')
      }
      if (price.value < minPrice.value) {
        throw new Error(`Price must be greater than minimum price ${minPrice.value} ${currency.value}`)
      }
      application.openPaymentPicker({
        processors: ['order2faka', 'wechat', 'alipay'],
        issue: {
          assigneeId,
          content: content.value,
          price: priceCent.value,
          currency: currency.value,
          publicIssue: publicIssue.value
        },
      })
    } catch (err) {
      showToast(err)
    }
  }

  return {
    currency,
    price,
    assign,
    content,
    publicIssue,
    currencies,
    bargainPriceText,
    resolvePriceText,
    minPrice
  }
}