<template>
  <pr-popup
    v-model="show"
    class="popup"
    position="bottom">
    <div
      v-show="!qrcodeDataUrl"
      class="picker-container">
      <div
        v-show="processors.length > 1"
        class="payment-radio">
        <div
          v-for="iterProrocessor in processors"
          :key="iterProrocessor"
          class="hoverable touchable"
          :class="{ selected: processor === iterProrocessor }"
          @click="processor = iterProrocessor">
          <img
            v-if="iterProrocessor === 'order2faka'"
            src="@/assets/giftcard.png"
            alt="giftcard">
          <img
            v-else-if="iterProrocessor === 'stripe'"
            src="@/assets/stripe.png"
            alt="stripe">
          <img
            v-else-if="iterProrocessor === 'alipay'"
            src="@/assets/icon-page-payment-alipay@3x.png"
            alt="alipay">
          <img
            v-else-if="iterProrocessor === 'wechat'"
            src="@/assets/icon-page-payment-wechat@3x.png"
            alt="wechat">
        </div>
      </div>

      <div
        v-if="processor === 'order2faka'"
        style="flex: 1; display: flex; flex-direction: column;">
        <mt-navbar
          v-model="selected"
          class="segmented-control">
          <mt-tab-item
            :id="0"
            class="touchable">{{ $t('buyGiftCode') }}</mt-tab-item>
          <mt-tab-item
            :id="1"
            class="touchable">{{ $t('useGiftCode') }}</mt-tab-item>
        </mt-navbar>

        <mt-tab-container
          v-model="selected"
          style="flex: 1; display: flex; flex-direction: column;">
          <mt-tab-container-item :id="0">
            <div class="payment-picker-tab">
              <div
                v-show="processor === 'order2faka'"
                class="processors-tip">
                <select-button
                  v-if="fakaSubProcessors.length > 1"
                  v-model="fakaSubProcessor"
                  :options="fakaSubProcessors"
                  option-value="value"
                  data-key="value">
                  <template #option="slotProps">
                    <span style="color: #333; font-size: 0.8rem;">{{ slotProps.option.name }}</span>
                  </template>
                </select-button>
                <img
                  v-else
                  src="@/assets/icon_page_payment_PayPal@3x.png"
                  alt="card">
              </div>
              <pr-button
                type="primary"
                :loading="loading"
                @click.native="prepare">{{ $t('buyGiftCode') }}</pr-button>
            </div>
          </mt-tab-container-item>

          <mt-tab-container-item :id="1">
            <div class="payment-picker-tab">
              <span
                class="p-float-label"
                style="margin-top: 2rem; margin-bottom: 1em;">
                <input-text
                  id="giftcode"
                  v-model="giftcode"
                  style="width: 100%;"></input-text>
                <label for="username">{{ $t('giftcode') }}</label>
              </span>

              <pr-button
                v-show="processor === 'order2faka'"
                type="primary"
                :loading="loading"
                @click.native="useGiftCode">{{ $t('useGiftCode') }}</pr-button>
            </div>
          </mt-tab-container-item>
        </mt-tab-container>
      </div>

      <pr-button
        v-else
        type="primary"
        :loading="loading"
        @click.native="prepare">Checkout</pr-button>
    </div>

    <div
      v-show="qrcodeDataUrl"
      class="qrcode-container">
      <img
        v-if="qrcodeDataUrl"
        :src="qrcodeDataUrl">
      <pr-button
        type="primary"
        @click.native="complete">{{ $t('paymentComplete') }}</pr-button>
    </div>
  </pr-popup>
</template>


<script>
//@ts-check
import PrButton from '@/components/PRButton.vue'
import postapi from '@/api/post'
import { computed, onMounted, ref, watch } from 'vue'
import { useCurrentCurrency } from '@/composables/currency'
import { APP_URL } from '@/config'
import { useRouter } from 'vue-router/composables'
import qrcode from 'qrcode'
import { intentChatPurchase } from '@/api/user'
import { buy as buyCircle } from '@/api/circle'
import { useApplication } from '@/pinia/application'
import { isGiftCodeValid } from '@/api/giftcode'
import { payOrder } from '@/api/order'
import InputText from 'primevue/inputtext'
import { useResponsive } from '@/composables/responsive'
import { useToast } from '@/composables/toast'
import mixpanel from 'mixpanel-browser'
import PrPopup from '@/components/PRPopup.vue'
import { useChannelStore } from '@/pinia/channel'
import { getProcessors } from '@/api/system'
import { useCurrentUser } from '@/composables/user_data'
import SelectButton from 'primevue/selectbutton'
import { useI18n } from 'vue-i18n-composable'
import { addRecord, sendIssue } from '@/api/issue'


export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup (props, { emit }) {
    const processor = ref('order2faka')
    const fakaSubProcessor = ref(null)
    const loading = ref(false)
    const { currency } = useCurrentCurrency()
    const { authenticated } = useCurrentUser()
    const router = useRouter()
    const qrcodeDataUrl = ref('')
    const show = ref(props.value)
    const giftcode = ref('')
    const selected = ref(0)
    const application = useApplication()
    const channelStore = useChannelStore()
    const { isOnMobile } = useResponsive()
    const directEpays = ref([])
    const fakaEpays = ref([])
    const { Toast } = useToast()
    const { t } = useI18n()


    const postId = computed(() => application.paymentPostId)
    const userId = computed(() => application.paymentUserId)
    const circleId = computed(() => application.paymentCircleId)
    const orderId = computed(() => application.paymentOrderId)
    const monthNumbers = computed(() => application.paymentMonthNumbers)
    const issue = computed(() => application.paymentIssue)
    const issueResolve = computed(() => application.paymentIssueResolve)

    const processors = computed(() => {
      const arr = []
      for (const directEpay of directEpays.value.values()) {
        if (directEpay.subProcessors.includes('alipay')) {
          arr.push('alipay')
        }
        if (directEpay.subProcessors.includes('wechat')) {
          arr.push('wechat')
        }
      }
      const subProcessors = Array.from(new Set(arr))
      const result = []
      if (subProcessors.includes('alipay')) {
        result.push('alipay')
      }
      result.push('order2faka')
      if (subProcessors.includes('wechat')) {
        result.push('wechat')
      }

      return result
    })

    const fakaSubProcessors = computed(() => {
      const processors = [{
        name: 'PayPal',
        value: 'paypal'
      }]
      if (fakaEpays.value.length > 0) {
        processors.push({
          // @ts-ignore
          name: t('alipay'),
          value: 'alipay'
        }, {
          name: t('wechatPay'),
          value: 'wechat'
        })
      }

      return processors
    })

    watch(props, () =>{
      show.value = props.value
    })

    watch(show, () => {
      emit('input', show.value)
      if (!show.value) {
        qrcodeDataUrl.value = ''
      }
    })

    watch(authenticated, () => {
      if (authenticated.value) {
        initProcessors()
      }
    })

    watch(processor, () => {
      if (processor.value === 'order2faka' && fakaSubProcessors.value[0]) {
        fakaSubProcessor.value = fakaSubProcessors.value[0].value
      } else {
        fakaSubProcessor.value = null
      }
    })

    watch(processors, () => {
      processor.value = processors.value[0]
    })

    const prepare = async function () {
      try {
        loading.value = true

        let promise = null

        if (postId.value) {
          promise = postapi.buy(postId.value, currency.value, {
            processor: processor.value,
            returnUrl: `${APP_URL}/posts/${postId.value}?justPaid=1&`,
            channelId: channelStore.sourceChannelId,
            subProcessor: fakaSubProcessor.value,
          })
        } else if (userId.value) {
          promise = intentChatPurchase(userId.value, currency.value, {
            processor: processor.value,
            returnUrl: `${APP_URL}/users/${userId.value}?showContact=1&`,
            subProcessor: fakaSubProcessor.value,
          })
        } else if (circleId.value) {
          promise = buyCircle(circleId.value, currency.value, {
            processor: processor.value,
            returnUrl: `${APP_URL}/circles/${circleId.value}?justPaid=1&`,
            monthNumbers: monthNumbers.value,
            subProcessor: fakaSubProcessor.value,
          })
        } else if (orderId.value) {
          promise = payOrder(orderId.value, {
            processor: processor.value,
            returnUrl: `${APP_URL}/i/orders?`,
            subProcessor: fakaSubProcessor.value,
          })
        } else if (issue.value) {
          promise = sendIssue(issue.value, {
            processor: processor.value,
            returnUrl: `${APP_URL}/users/${issue.value.assigneeId}/commissions?`,
            subProcessor: fakaSubProcessor.value,
          })
        } else if (issueResolve.value) {
          promise = addRecord(issueResolve.value.issueId, {
            status: 'waiting-for-resolve',
            processor: processor.value,
            returnUrl: `${APP_URL}/commissions/${issueResolve.value.issueId}?`,
            subProcessor: fakaSubProcessor.value,
          })
        }

        if (promise) {
          const res = await promise

          mixpanel.track('Perform Payment', {
            userId: userId.value,
            postId: postId.value,
            circleId: circleId.value,
            orderId: orderId.value
          })

          if (res.tmpSecret) {
            return router.push({
              path: '/checkout',
              query: {
                postId: postId.value,
                userId: userId.value,
                circleId: circleId.value,
                clientSecret: res.tmpSecret,
                channelId: channelStore.sourceChannelId,
              }
            })
          } else if (res.payurl) {
            const newWindow = window.open(res.payurl, '_blank')
            if (!newWindow) {
              return location.href = res.payurl
            }
          } else if (res.qrcode) {
            return qrcodeDataUrl.value = await qrcode.toDataURL(res.qrcode)
          } else if (res.url) {
            if (isOnMobile.value) {
              return location.href = res.url
            }
            return window.open(res.url, '_blank')
          }
        }
      } catch (err) {
        console.error(err)
        if (
          circleId.value &&
          err.message &&
          (err.message.includes('join ok') || err.message.includes('active'))
        ) {
          return location.reload()
        }

        Toast({
          message: err.message ? err.message : err,
        })
      } finally {
        loading.value = false
      }
    }

    const complete = function () {
      show.value = false

      if (postId.value) {
        location.href = `${APP_URL}/posts/${postId.value}?justPaid=1`
      } else if (userId.value) {
        location.href = `${APP_URL}/users/${userId.value}?showContact=1`
      } else if (circleId.value) {
        location.href = `${APP_URL}/circles/${circleId.value}?justPaid=1`
      } else if (orderId.value) {
        location.href = `${APP_URL}/i/orders`
      }
    }

    const useGiftCode = async function () {
      try {
        loading.value = true
        let itemId = null
        if (postId.value) {
          itemId = postId.value
        } else if (circleId.value) {
          itemId = circleId.value
        } else if (userId.value) {
          itemId = userId.value
        }

        if (!itemId) {
          throw new Error('invalid item id')
        }

        const ok = await isGiftCodeValid(giftcode.value, {
          artworkId: itemId
        })
        if (!ok) {
          throw new Error('invalid gift code')
        }
        complete()

        mixpanel.track('Complete Payment', {
          userId: userId.value,
          postId: postId.value,
          circleId: circleId.value,
          orderId: orderId.value
        })
      } catch (err) {
        console.error(err)
        Toast({
          message: err.message ? err.message : err,
        })
      } finally {
        loading.value = false
      }
    }

    const initProcessors = async function () {
      try {
        if (!authenticated.value) {
          return
        }

        const result = await getProcessors()
        fakaEpays.value = result.fakaEpays
        directEpays.value = result.directEpays
      } catch (err) {
        console.error(err)
        Toast(err.message)
      }
    }

    onMounted(() => {
      initProcessors()
    })

    return {
      prepare,
      processor,
      loading,
      qrcodeDataUrl,
      show,
      complete,
      processors,
      giftcode,
      selected,
      useGiftCode,
      fakaEpays,
      fakaSubProcessor,
      fakaSubProcessors,
    }
  },
  components: {
    PrButton,
    InputText,
    PrPopup,
    SelectButton,
  }
}
</script>

<style>
.popup .p-dialog-content {
  min-height: 20rem;
}

.popup.p-sidebar {
  height: 20rem;
}
</style>

<style lang="less">
@import "@/assets/base.less";


.popup .p-sidebar-content, .popup .p-dialog-content {
  width: 26rem;
  @media @phone {
    width: calc(100% - 2rem);
  }
  margin-left: auto;
  margin-right: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.popup .mint-tab-container-wrap {
  flex: 1;
}
</style>

<style scoped lang="less">
@import "@/assets/base.less";

button {
  width: 100%;
}

.picker-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  @media @desktop {
    min-width: 22rem;
  }
}

.payment-radio {
  margin-top: 18px;
  margin-bottom: 1em;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 0.5em;
  width: 100%;

  div {
    display: inline-block;
    width: 45%;
    height: 36px;
    line-height: 36px;
    border: solid 1px rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    img {
      display: inline-block;
      height: 2em;
    }

    &.selected {
      border: solid 1px @main;
    }

    img[alt='alipay'], img[alt='wechat'] {
      height: 1em;
    }
  }
}

.qrcode-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;
}

.processors-tip {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3em;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-top: 1rem;

  img[alt='alipay'] {
    height: 1em;
  }

  img[alt='wechat'] {
    height: 1.6em;
  }

  img[alt='card'] {
    height: 1.4rem;
  }
}

.payment-picker-tab {
  height: 100%;
  display: flex;
  flex-direction: column;

  button {
    margin-top: auto;
  }
}
</style>