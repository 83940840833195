<template>
  <div
    v-if="post"
    class="item-container"
    :class="{
      gray: post.fake
    }">
    <div class="item-header">
      <user-avatar
        class="item-avatar"
        :class="{'item-avatar-pc-postlistitem': !isOnMobile}" 
        :user="post.user"
        :disabled="true"
        @click.stop.native="$router.push(`/users/${post.user._id}`)"></user-avatar>
      <h5
        class="item-nickname"
        :class="{'item-nickname-pc-postlistitem': !isOnMobile}">
        {{ nickname }}
        <circle-badge
          v-if="post.user.tag && post.user.tag.visible"
          :member-tag="post.user.tag.memberTag"
          @click.native.stop="$router.push(`/user/${post.user.tag.authorId}/circles`)"></circle-badge>
      </h5>
      <span
        :class="{
          'user-avatar-span-pc-postlistitem': !isOnMobile,
        }">
        {{ createdAt }}&nbsp;&nbsp;
        {{ price === $t('free') ? $t('free') : priceText }}
      </span>

      <tags
        :class="{'tag-tuchong':!isOnMobile}"
        :tags="post.tags"></tags>
    </div>

    <img
      v-if="post.fake"
      class="fake-badge"
      src="@/assets/fake_original.png"
      alt="fake">

    <div
      class="item-indicators-container itemindicatorscontainermode">
      <a
        v-if="post.type !== 'article'"
        class="indicator hoverable"
        @click.prevent.stop="intentStar">
        <img
          v-if="!post.starred"
          src="@/assets/icon_postdetail_Star_switchoff.png">
        <img
          v-else
          src="@/assets/icon_postdetail_Star_hover&switchon.png">
        <span>{{ post.hideStars ? '' : post.starCount }}</span>
      </a>

      <a
        class="indicator hoverable"
        @click.prevent.stop="like">
        <img
          v-if="!post.liked"
          src="@/assets/icon_postdetail_like_switchoff@2x.png">
        <img
          v-else
          src="@/assets/icon_postdetail_like_hover&switchon@2x.png">
        <span>{{ post.likes }}</span>
      </a>

      <!-- <a
        v-if="post.pictures.filter(media => media.type === MediaType.IMAGE).length > 0 && post.type !== 'article'"
        class="indicator hoverable"
        @click.prevent.stop="downloadZip">
        <img src="@/assets/icon_postdetail_download_switchoff@2x.png">
      </a> -->
    </div>


    <more-menu
      v-model="moreMenuVisible"
      :items="menuItems">
      <span
        v-if="isOnMobile"
        class="more touchable hoverable moreicon"
        @click.prevent.stop="moreMenuVisible = !moreMenuVisible">
        <img src="@/assets/Navigation_More@2x.png">
      </span>
      <span
        v-else
        class="more touchable hoverable moreicon"
        @click.prevent.stop="moreMenuVisible = !moreMenuVisible"
        @mouseover="moreMenuVisible = true"
        @mouseleave="moreMenuVisible = false">
        <img src="@/assets/Navigation_More@2x.png">
      </span>
    </more-menu>

    <media-list :post="post"></media-list>

    <div
      v-if="post.channels.length > 0"
      class="channels">
      <p>{{ $t('artworkChannel') }}</p>
      <div
        v-for="channel in post.channels"
        :key="channel._id"
        class="channel"
        @click="$router.push(`/channels/${channel._id}`)">
        {{ channel.title }}
      </div>
    </div>

    <!--report-->
    <mt-popup
      v-if="reportShow"
      v-show="reportShow"
      :position="isOnMobile ? 'right' : 'middle'"
      :modal="true"
      :close-on-click-modal="true">
      <report
        :pics-component="post"
        :post-id-component="post._id"
        :nickname-component="nickname"
        :user-id-component="post.user._id"
        :url="true"
        @close="reportShow = false"></report>
    </mt-popup>

    <media-saver
      v-if="post._id"
      v-model="collectorShow"
      :post="post"
      @completed="onStarCompleted"></media-saver>
  </div>
</template>


<script>
//@ts-check
import Tags from '@/components/Tags.vue'
import MediaList from '@/components/MediaList.vue'
import UserAvatar from '@/components/UserAvatar.vue'
import CircleBadge from '@/components/CircleBadge.vue'
import Report from '@/pages/Report.vue'
import { useArtworkPresent } from '@/composables/artwork_present'
import { useArtworkPurchase } from '@/composables/artwork_purchase'
import { useCurrentCurrency } from '@/composables/currency'
import { usePostMoreMenu } from '@/composables/artwork_moremenu'
import { useResponsive } from '@/composables/responsive'
import MediaSaver from '@/components/MediaSaver.vue'
import { useArtworkActions } from '@/composables/artwork_action'
import MoreMenu from '@/components/MoreMenu.vue'
import { MediaType } from '@/utils/defines'


export default {
  props: {
    post: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default: 'overview'
    },
  },
  setup (props) {
    const { currency } = useCurrentCurrency()

    return {
      ...useArtworkPresent(props.post._id, currency, props.post),
      ...useArtworkPurchase(props.post._id),
      currency,
      ...usePostMoreMenu(props.post),
      ...useResponsive(),
      ...useArtworkActions(props.post._id),
      MediaType,
    }
  },
  methods: {
    confirm () {
      this.buyPost({
        postId: this.post._id,
      })
    },
  },
  components: {
    MediaList,
    Tags,
    UserAvatar,
    CircleBadge,
    Report,
    MediaSaver,
    MoreMenu,
  }
}
</script>


<style scoped lang="less">
@import "@/assets/base.less";

.item-container {
  width: 100%;
  margin-top: 1em;
  background: white;
  position: relative;
  border-bottom: solid 1px @border;

  a {
    display: block;
    float: left;
    margin-bottom: 0.85em;
    margin-right: 0.85em;
    font-size: 0.85em;
    height: 1.7em;
    line-height: 1.7em;

    &:first-child {
      margin-left: 5px;
    }
  }

  .more {
    position: absolute;
    right: 8px;
    top: 45px;

    img {
      width: 20px;
    }
  }

  .moreicon {
    top: 22px;
  }
}

.item-header {
  text-align: left;

  span {
    position: absolute;
    left: 6.1em;
    top: 3.4em;
    font-size: 12px;
    color: @grey;
  }

  .user-avatar-span-pc-postlistitem {
    left: 5.1em;
  }

  .item-avatar {
    width: 50px;
    height: 50px;
    margin-left: 0.85em;
    margin-top: 0.85em;
    border-radius: 25px;
  }

  .item-avatar-pc-postlistitem {
    margin-left: 0em;
  }

  .item-nickname {
    position: absolute;
    left: 4.6em;
    top: 1.3em;
    font-size: 15px;
    color: @black;
    font-weight: 500;
  }
  
  .item-nickname-pc-postlistitem {
    left: 4.0em;
  }

}


.item-indicators-container {
  position: absolute;
  right: 0;
  top: 75px;

  .indicator {
    img {
      width: 20px;
    }

    span {
      margin-left: 0.4em;
      font-size: 12px;
      color: @grey;
    }
  }
}

.itemindicatorscontainermode {
  right: 30px;
  top: 20px;
}

.gray {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.fake-badge {
  display: block;
  position: absolute;
  top: 15px;
  right: 20px;
  width: 120px;
  z-index: 10;
}

.item-bottom-container {
  width: 100%;
  height: 1.9em;
  padding-bottom: 0.5em;
}

a {
  &.right {
    float: right;
  }

  &.weibo {
    margin-left: 5px;

    img {
      width: 30px;
    }
  }

  &.report {
    margin: 0;
    margin-top: 6px;
    margin-right: 10px;
  }

  span {
    color: #B4B4B4;
    vertical-align: middle;
    margin-left: -3px;
  }

  img {
    width: 32px;
    vertical-align: middle;
  }
}

.channels {
  margin-top: 1em;
  margin-bottom: 1em;
  background: white;

  p {
    padding-left: 0.6em;
    margin-bottom: 0.5em;
    font-size: 14px;
    color: @grey;
  }

  .channel {
    display: inline-block;
    padding-left: 1em;
    padding-right: 1em;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    font-weight: 400;
    color: #9b9b9b;
    border-radius: 4px;
    margin-left: 0.7em;
    margin-bottom: 0.3em;
    background: #f1f1f1;

    &:active {
      background-color: rgba(0, 0, 0, 0.05);
    }

    &:hover {
      cursor: pointer;
      opacity: 0.65;
    }
  }
}

.tag-tuchong {
  margin-bottom: 0em !important;
  margin-left: -0.7em !important;
}
</style>
